import { FC, memo, useMemo } from 'react';
import DateRenderer from './DateRenderer';

const NextReviewRenderer: FC<{ data: { nextReviewStartDate?: string } | null }> = (props) => {
  const data = useMemo(() => props.data?.nextReviewStartDate || null, [props]);

  return <DateRenderer data={data} />;
};

export default memo(NextReviewRenderer);
